<script>
export default {
  name: "client-pagination",
  props: {
    currentPage: {
      type: Number,
      default: 1,
      required: true
    },
    totalPages: {
      type: Number,
      default: 0,
      required: true
    },
    isSearch: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {

  },
  methods: {
    nextPage() {
      this.$emit('nextPage')
    },
    prevPage() {
      this.$emit('previousPage')
    },
  },

  computed: {
    numberPage(){
      if (!this.isSearch){
        return 0
      } else {
        return this.totalPages
      }
    }
  }
}
</script>

<template>
  <div class="mt-4">
    <nav aria-label="Page navigation mt-5">
      <ul class="pagination justify-content-end">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="prevPage" :disabled="currentPage === 1">
            ←
          </button>
        </li>
        <li class="page-item" v-for="page in numberPage" :key="page" :class="{ active: currentPage === page }">
          <button class="page-link" @click="$emit('changePage', page)">
            {{ page }}
          </button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages">
            →
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped>

</style>